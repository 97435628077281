import { Packet } from "../Packet";
export class S22ShippingCarrier extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 22;
    }
}
(function (S22ShippingCarrier) {
    let Action;
    (function (Action) {
        Action[Action["GET_ALL"] = 0] = "GET_ALL";
        Action[Action["ADD"] = 1] = "ADD";
        Action[Action["REMOVE"] = 2] = "REMOVE";
        Action[Action["ADD_SERVICE"] = 3] = "ADD_SERVICE";
        Action[Action["REMOVE_SERVICE"] = 4] = "REMOVE_SERVICE";
        Action[Action["GET_RICH"] = 5] = "GET_RICH";
        Action[Action["GET_ALL_SERVICES"] = 6] = "GET_ALL_SERVICES";
    })(Action = S22ShippingCarrier.Action || (S22ShippingCarrier.Action = {}));
    ;
})(S22ShippingCarrier || (S22ShippingCarrier = {}));
