import { Packet } from "../Packet";
export class S00Ping extends Packet {
    constructor(pingTime, lat, lng, latLngTrackTime) {
        super();
        this.pingTime = pingTime;
        this.lat = lat;
        this.lng = lng;
        this.latLngTrackTime = latLngTrackTime;
    }
    getPacketID() {
        return 0;
    }
}
