import { Packet } from "../Packet";
export class S27WarehouseComponent extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 27;
    }
}
(function (S27WarehouseComponent) {
    let Action;
    (function (Action) {
        Action[Action["ADD"] = 1] = "ADD";
        Action[Action["GET_ALL_WITH_PARAMS"] = 2] = "GET_ALL_WITH_PARAMS";
        Action[Action["GET_RICH"] = 3] = "GET_RICH";
        Action[Action["GET_SUGGESTED_SECTORS"] = 4] = "GET_SUGGESTED_SECTORS";
        Action[Action["MOVE"] = 5] = "MOVE";
        Action[Action["EDIT"] = 6] = "EDIT";
        Action[Action["ADD_SUPPLY"] = 7] = "ADD_SUPPLY";
    })(Action = S27WarehouseComponent.Action || (S27WarehouseComponent.Action = {}));
    ;
})(S27WarehouseComponent || (S27WarehouseComponent = {}));
