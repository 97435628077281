import { Packet } from "../Packet";
export class S40ManufacturingComponent extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 40;
    }
}
(function (S40ManufacturingComponent) {
    let Action;
    (function (Action) {
        Action[Action["GET_MANUFACTURING_COMPONENT_PROCESS_RICH"] = 1] = "GET_MANUFACTURING_COMPONENT_PROCESS_RICH";
        Action[Action["SET_MANUFACTURING_COMPONENT_PROCESS_OPERATION_TYPE"] = 2] = "SET_MANUFACTURING_COMPONENT_PROCESS_OPERATION_TYPE";
        Action[Action["CREATE_MULTISPINDLE_DRILL"] = 3] = "CREATE_MULTISPINDLE_DRILL";
        Action[Action["CREATE_MULTISPINDLE_DRILL_PLANE"] = 4] = "CREATE_MULTISPINDLE_DRILL_PLANE";
        Action[Action["TEST_MULTISPINDLE_DRILL_PLANE"] = 5] = "TEST_MULTISPINDLE_DRILL_PLANE";
        Action[Action["SAVE_MULTISPINDLE_DRILL_PLANE"] = 6] = "SAVE_MULTISPINDLE_DRILL_PLANE";
        Action[Action["CREATE_HELP_VALUE"] = 7] = "CREATE_HELP_VALUE";
        Action[Action["SAVE_HELP_VALUE"] = 8] = "SAVE_HELP_VALUE";
        Action[Action["SET_MANUFACTURING_COMPONENT_PROCESS_MULTISPINDLE_DRILL_LABEL_PRINT_TYPE"] = 9] = "SET_MANUFACTURING_COMPONENT_PROCESS_MULTISPINDLE_DRILL_LABEL_PRINT_TYPE";
        Action[Action["SAVE_MULTISPINDLE_DRILL_PLANE_REF_DATA"] = 10] = "SAVE_MULTISPINDLE_DRILL_PLANE_REF_DATA";
        Action[Action["CREATE_CNC_DRILL"] = 11] = "CREATE_CNC_DRILL";
        Action[Action["SAVE_CNC_DRILL"] = 12] = "SAVE_CNC_DRILL";
        Action[Action["TEST_CNC_DRILL"] = 13] = "TEST_CNC_DRILL";
        Action[Action["TEST_MANUFACTURING_COMPONENT_CYCLE_TIME"] = 14] = "TEST_MANUFACTURING_COMPONENT_CYCLE_TIME";
        Action[Action["GET_CYCLE_TIME_BY_PROCESS"] = 15] = "GET_CYCLE_TIME_BY_PROCESS";
        Action[Action["CREATE_GET_CYCLE_TIME_BY_PROCESS"] = 16] = "CREATE_GET_CYCLE_TIME_BY_PROCESS";
    })(Action = S40ManufacturingComponent.Action || (S40ManufacturingComponent.Action = {}));
    ;
})(S40ManufacturingComponent || (S40ManufacturingComponent = {}));
