import { Packet } from "../Packet";
export class S25OrderDelivery extends Packet {
    constructor(action, orderId) {
        super();
        this.action = action;
        this.orderId = orderId;
    }
    getPacketID() {
        return 25;
    }
}
(function (S25OrderDelivery) {
    let Action;
    (function (Action) {
        Action[Action["GET_ORDER_ALL"] = 1] = "GET_ORDER_ALL";
        Action[Action["CREATE_ORDER_ALL"] = 2] = "CREATE_ORDER_ALL";
    })(Action = S25OrderDelivery.Action || (S25OrderDelivery.Action = {}));
    ;
})(S25OrderDelivery || (S25OrderDelivery = {}));
