import { Packet } from "../Packet";
export class S10UserList extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 10;
    }
}
(function (S10UserList) {
    let Action;
    (function (Action) {
        Action[Action["GET_ALL"] = 0] = "GET_ALL";
        Action[Action["GET_ALL_ROLE_FILTER"] = 1] = "GET_ALL_ROLE_FILTER";
        Action[Action["GET_COMPETENCES"] = 2] = "GET_COMPETENCES";
        Action[Action["GET_ONE_RICH"] = 3] = "GET_ONE_RICH";
        Action[Action["SET_USER_COMPETENCE"] = 4] = "SET_USER_COMPETENCE";
        Action[Action["GET_COMPETENCE_MATRIX_LIST"] = 5] = "GET_COMPETENCE_MATRIX_LIST";
        Action[Action["GET_COMPETENCE_MATRIX_RICH"] = 6] = "GET_COMPETENCE_MATRIX_RICH";
        Action[Action["COMPETENCE_MATRIX_ADD_USER"] = 7] = "COMPETENCE_MATRIX_ADD_USER";
        Action[Action["COMPETENCE_MATRIX_REMOVE_USER"] = 8] = "COMPETENCE_MATRIX_REMOVE_USER";
        Action[Action["COMPETENCE_MATRIX_ADD_COMPETENCE"] = 9] = "COMPETENCE_MATRIX_ADD_COMPETENCE";
        Action[Action["COMPETENCE_MATRIX_REMOVE_COMPETENCE"] = 10] = "COMPETENCE_MATRIX_REMOVE_COMPETENCE";
    })(Action = S10UserList.Action || (S10UserList.Action = {}));
    ;
})(S10UserList || (S10UserList = {}));
