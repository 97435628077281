import { Packet } from "../Packet";
export class S21OrderPayment extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 21;
    }
}
(function (S21OrderPayment) {
    let Action;
    (function (Action) {
        Action[Action["ADD"] = 1] = "ADD";
        Action[Action["MODIFY"] = 2] = "MODIFY";
        Action[Action["REMOVE"] = 3] = "REMOVE";
        Action[Action["GET_ALL_REFUNDS"] = 4] = "GET_ALL_REFUNDS";
        Action[Action["GET_ALL_REFUNDS_RICH"] = 5] = "GET_ALL_REFUNDS_RICH";
        Action[Action["SOLVE_CONFLICT"] = 6] = "SOLVE_CONFLICT";
        Action[Action["CHECK_CONFLICT"] = 7] = "CHECK_CONFLICT";
        Action[Action["ACCEPT_ORDER_REFUND"] = 8] = "ACCEPT_ORDER_REFUND";
        Action[Action["DENY_ORDER_REFUND"] = 9] = "DENY_ORDER_REFUND";
        Action[Action["GET_ORDER_REFUND"] = 10] = "GET_ORDER_REFUND";
    })(Action = S21OrderPayment.Action || (S21OrderPayment.Action = {}));
    ;
})(S21OrderPayment || (S21OrderPayment = {}));
