import { Packet } from "../Packet";
export class S51WarehouseComponentPacking extends Packet {
    // public warehouseComponentRequiredPackingsessionClaimId: number;
    // public warehouseComponentRequiredPackArrangeElId: number;
    // public warehouseComponentRequiredPackArrangeBinId: number;
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 51;
    }
}
(function (S51WarehouseComponentPacking) {
    let Action;
    (function (Action) {
        Action[Action["START_PACKING_SESSION"] = 0] = "START_PACKING_SESSION";
        Action[Action["SET_ONCART"] = 1] = "SET_ONCART";
        Action[Action["GET_PACKING_SESSION_RICH"] = 2] = "GET_PACKING_SESSION_RICH";
        Action[Action["GET_PACKING_SESSION_ARRANGED_RICH"] = 3] = "GET_PACKING_SESSION_ARRANGED_RICH";
        Action[Action["SET_PACKING_SESSION_ARRANGE_EL_STATUS"] = 4] = "SET_PACKING_SESSION_ARRANGE_EL_STATUS";
        Action[Action["SET_PACKING_SESSION_ARRANGE_STATUS"] = 5] = "SET_PACKING_SESSION_ARRANGE_STATUS";
        Action[Action["GET_PACKING_SPRINT_OPTIONS"] = 6] = "GET_PACKING_SPRINT_OPTIONS";
        Action[Action["GET_NEXT_JOB"] = 7] = "GET_NEXT_JOB";
        Action[Action["SET_BIN_ELEMENT_PACKED"] = 8] = "SET_BIN_ELEMENT_PACKED";
        Action[Action["SET_BIN_PACKED"] = 9] = "SET_BIN_PACKED";
        Action[Action["SET_BIN_PACK_START"] = 10] = "SET_BIN_PACK_START";
        Action[Action["GET_WAREHOUSE_COMPONENT_REQUIRED_JOBS"] = 11] = "GET_WAREHOUSE_COMPONENT_REQUIRED_JOBS";
        Action[Action["PRINT_COMPONENT_PRODUCT_PACKING_DOC"] = 12] = "PRINT_COMPONENT_PRODUCT_PACKING_DOC";
        Action[Action["SOLVE_PACKING_SESSION_CLAIM_MISHAP"] = 13] = "SOLVE_PACKING_SESSION_CLAIM_MISHAP";
        Action[Action["EXECUTE_PACKING_SESSION_CLAIM_MISHAP"] = 14] = "EXECUTE_PACKING_SESSION_CLAIM_MISHAP";
        Action[Action["PRINT_BIN_LABELS"] = 15] = "PRINT_BIN_LABELS";
        Action[Action["REVERT_PACKING_CLAIM"] = 16] = "REVERT_PACKING_CLAIM";
    })(Action = S51WarehouseComponentPacking.Action || (S51WarehouseComponentPacking.Action = {}));
    ;
})(S51WarehouseComponentPacking || (S51WarehouseComponentPacking = {}));
