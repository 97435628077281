import { Packet } from "../Packet";
export class S24Components extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 24;
    }
}
(function (S24Components) {
    let Action;
    (function (Action) {
        Action[Action["CREATE"] = 0] = "CREATE";
        Action[Action["GET_ALL"] = 1] = "GET_ALL";
        Action[Action["ADD_PARAM"] = 2] = "ADD_PARAM";
        Action[Action["GET_RICH"] = 3] = "GET_RICH";
        Action[Action["GET_ALL_RICH"] = 4] = "GET_ALL_RICH";
        Action[Action["ADD_SUPPLIER"] = 5] = "ADD_SUPPLIER";
        Action[Action["SET_NAME"] = 6] = "SET_NAME";
        Action[Action["ADD_PACKING_CARTOON"] = 7] = "ADD_PACKING_CARTOON";
        Action[Action["GET_PACKING_INFO"] = 8] = "GET_PACKING_INFO";
        Action[Action["GET_PARAMS_SUGGESTIONS"] = 9] = "GET_PARAMS_SUGGESTIONS";
        Action[Action["SAVE_PACKING_CARTON"] = 10] = "SAVE_PACKING_CARTON";
        Action[Action["SET_FILES_PATH"] = 11] = "SET_FILES_PATH";
        Action[Action["GET_COMPONENT_FILE"] = 12] = "GET_COMPONENT_FILE";
        Action[Action["DELETE_COMPONENT_FILE"] = 13] = "DELETE_COMPONENT_FILE";
        Action[Action["SAVE_COMPONENT_FILE"] = 14] = "SAVE_COMPONENT_FILE";
        Action[Action["GET_ALL_FILE_PATHS"] = 15] = "GET_ALL_FILE_PATHS";
        Action[Action["CREATE_ALL_FILES"] = 16] = "CREATE_ALL_FILES";
    })(Action = S24Components.Action || (S24Components.Action = {}));
    ;
})(S24Components || (S24Components = {}));
