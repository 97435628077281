export class PacketClient {
    getPacketId() {
        return this.packetId;
    }
    getResponseId() {
        return this.responseId;
    }
    getErrorMsg() {
        return this.errorMsg;
    }
    setErrorMsg(errorMsg) {
        this.errorMsg = errorMsg;
    }
    getErrorCode() {
        return this.errorCode;
    }
    getPacketPayload() {
        return this.packetPayload;
    }
    getPacket() {
        return this.packet;
    }
    setPacket(packet) {
        this.packet = packet;
    }
}
