import { Packet } from "../Packet";
export class S36ProductOpinion extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 36;
    }
}
(function (S36ProductOpinion) {
    let Action;
    (function (Action) {
        Action[Action["GET_ALL"] = 1] = "GET_ALL";
        Action[Action["SET_RATING"] = 2] = "SET_RATING";
        Action[Action["SET_NAME"] = 3] = "SET_NAME";
        Action[Action["SET_VERIFIED"] = 4] = "SET_VERIFIED";
        Action[Action["SET_HIGHLIGHTED"] = 5] = "SET_HIGHLIGHTED";
        Action[Action["SET_DATE_DISPLAYED"] = 6] = "SET_DATE_DISPLAYED";
        Action[Action["GET_ONE_RICH"] = 8] = "GET_ONE_RICH";
        Action[Action["SET_LANG"] = 9] = "SET_LANG";
        Action[Action["REMOVE_LANG"] = 10] = "REMOVE_LANG";
        Action[Action["ADD_IMAGE"] = 11] = "ADD_IMAGE";
        Action[Action["REMOVE_IMAGE"] = 12] = "REMOVE_IMAGE";
        Action[Action["SET_RELEVANCE"] = 13] = "SET_RELEVANCE";
    })(Action = S36ProductOpinion.Action || (S36ProductOpinion.Action = {}));
    ;
})(S36ProductOpinion || (S36ProductOpinion = {}));
