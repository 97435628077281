import { Packet } from "../Packet";
export class S08Order extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 8;
    }
}
(function (S08Order) {
    let Action;
    (function (Action) {
        Action[Action["DELIVERY_AUTO_ADD"] = 5] = "DELIVERY_AUTO_ADD";
        Action[Action["DELIVERY_EMPTY_ADD"] = 6] = "DELIVERY_EMPTY_ADD";
        Action[Action["ACCEPT_ORDER"] = 7] = "ACCEPT_ORDER";
        Action[Action["REVERT_ORDER"] = 8] = "REVERT_ORDER";
        Action[Action["SET_SHIPPING_CARRIER_SERVICE"] = 9] = "SET_SHIPPING_CARRIER_SERVICE";
        Action[Action["GET_ALL_ORDERS"] = 10] = "GET_ALL_ORDERS";
        Action[Action["GET_ORDER_RICH"] = 11] = "GET_ORDER_RICH";
        Action[Action["CREATE_ORDER_DISCOUNT"] = 12] = "CREATE_ORDER_DISCOUNT";
        Action[Action["REMOVE_ORDER_DISCOUNT"] = 13] = "REMOVE_ORDER_DISCOUNT";
        Action[Action["CREATE_ORDER_PAYMENT_REFUND"] = 14] = "CREATE_ORDER_PAYMENT_REFUND";
        Action[Action["CREATE_ORDER_PAYMENT_REFUND_EXECUTION"] = 15] = "CREATE_ORDER_PAYMENT_REFUND_EXECUTION";
        Action[Action["REMOVE_ORDER_PAYMENT_REFUND"] = 16] = "REMOVE_ORDER_PAYMENT_REFUND";
        Action[Action["REMOVE_ORDER_PAYMENT_REFUND_EXECUTION"] = 17] = "REMOVE_ORDER_PAYMENT_REFUND_EXECUTION";
        Action[Action["CREATE_BOOKKEEPING"] = 18] = "CREATE_BOOKKEEPING";
        Action[Action["REMOVE_BOOKKEEPING"] = 19] = "REMOVE_BOOKKEEPING";
        Action[Action["SET_PAYER"] = 20] = "SET_PAYER";
        Action[Action["REMOVE_PAYER"] = 21] = "REMOVE_PAYER";
        Action[Action["CANCEL_ORDER"] = 22] = "CANCEL_ORDER";
    })(Action = S08Order.Action || (S08Order.Action = {}));
    ;
})(S08Order || (S08Order = {}));
