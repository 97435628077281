import { Packet } from "../Packet";
export class S29SupplierProduct extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 29;
    }
}
(function (S29SupplierProduct) {
    let Action;
    (function (Action) {
        Action[Action["GET_ALL"] = 0] = "GET_ALL";
        Action[Action["CREATE"] = 1] = "CREATE";
        Action[Action["GET_ALL_DIRECT"] = 2] = "GET_ALL_DIRECT";
        Action[Action["GET_RICH"] = 3] = "GET_RICH";
        Action[Action["CREATE_PARAM"] = 4] = "CREATE_PARAM";
        Action[Action["GET_ALL_PARAMRICH"] = 5] = "GET_ALL_PARAMRICH";
        Action[Action["SET_CONDITION"] = 6] = "SET_CONDITION";
        Action[Action["SET_PARAM_TRANSFORM"] = 7] = "SET_PARAM_TRANSFORM";
        Action[Action["ADD_SUPPLIER"] = 8] = "ADD_SUPPLIER";
        Action[Action["ADD_PACKAGE"] = 9] = "ADD_PACKAGE";
        Action[Action["SET_SUPPLIER_EXTERNAL_NAME"] = 10] = "SET_SUPPLIER_EXTERNAL_NAME";
        Action[Action["SET_SUPPLIER_ACTIVE"] = 11] = "SET_SUPPLIER_ACTIVE";
        Action[Action["SET_BASE_PRICE_CURRENCY"] = 12] = "SET_BASE_PRICE_CURRENCY";
        Action[Action["ADD_SURCHARGE"] = 13] = "ADD_SURCHARGE";
        Action[Action["SAVE_SURCHARGE"] = 14] = "SAVE_SURCHARGE";
        Action[Action["DELETE_SURCHARGE"] = 15] = "DELETE_SURCHARGE";
        Action[Action["SAVE_PACKAGE"] = 16] = "SAVE_PACKAGE";
        Action[Action["DELETE_PACKAGE"] = 17] = "DELETE_PACKAGE";
        Action[Action["RENAME_SUPPLIER_PRODUCT"] = 18] = "RENAME_SUPPLIER_PRODUCT";
        Action[Action["SET_COMPONENT_PRODUCT"] = 19] = "SET_COMPONENT_PRODUCT";
        Action[Action["SET_SUPPLIER_PRODUCT_DIRECT_COMPONENT_PRODUCT_TRANSFORM_MAP"] = 20] = "SET_SUPPLIER_PRODUCT_DIRECT_COMPONENT_PRODUCT_TRANSFORM_MAP";
        Action[Action["SET_SUPPLIER_PRIDUCT_DIRECT_ORDER_IMAGE_URL"] = 21] = "SET_SUPPLIER_PRIDUCT_DIRECT_ORDER_IMAGE_URL";
        Action[Action["DELETE_PARAM"] = 22] = "DELETE_PARAM";
        Action[Action["UPDATE_PARAM"] = 23] = "UPDATE_PARAM";
        Action[Action["UPDATE_DIRECT"] = 24] = "UPDATE_DIRECT";
        Action[Action["DELETE_TRANSFORM"] = 25] = "DELETE_TRANSFORM";
        Action[Action["SAVE_TRANSFORM"] = 26] = "SAVE_TRANSFORM";
        Action[Action["GET_SUPPLIER_PRODUCT_PARAMS"] = 27] = "GET_SUPPLIER_PRODUCT_PARAMS";
        Action[Action["DELETE_COMPLEX"] = 28] = "DELETE_COMPLEX";
        Action[Action["UPDATE_COMPLEX"] = 29] = "UPDATE_COMPLEX";
        Action[Action["CREATE_COMPLEX"] = 30] = "CREATE_COMPLEX";
    })(Action = S29SupplierProduct.Action || (S29SupplierProduct.Action = {}));
    ;
})(S29SupplierProduct || (S29SupplierProduct = {}));
