import { Packet } from "../Packet";
export class S20OrderProduct extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 20;
    }
}
(function (S20OrderProduct) {
    let Action;
    (function (Action) {
        Action[Action["GET_RICH"] = 0] = "GET_RICH";
        Action[Action["UNBIND"] = 1] = "UNBIND";
        Action[Action["REMOVE"] = 2] = "REMOVE";
        Action[Action["UPDATE_PARAM_VALUE"] = 3] = "UPDATE_PARAM_VALUE";
        Action[Action["ADD"] = 4] = "ADD";
        Action[Action["AUTO_ASSIGN"] = 5] = "AUTO_ASSIGN";
        Action[Action["GET_ALL_BY_ORDER_ID"] = 6] = "GET_ALL_BY_ORDER_ID";
    })(Action = S20OrderProduct.Action || (S20OrderProduct.Action = {}));
    ;
})(S20OrderProduct || (S20OrderProduct = {}));
