import { Packet } from "../Packet";
export class S11Loading extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 11;
    }
}
(function (S11Loading) {
    let Action;
    (function (Action) {
    })(Action = S11Loading.Action || (S11Loading.Action = {}));
    ;
})(S11Loading || (S11Loading = {}));
