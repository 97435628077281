import { Packet } from "../Packet";
export class S06Route extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 6;
    }
}
(function (S06Route) {
    let Action;
    (function (Action) {
        Action[Action["GET_RICH_DATA"] = 0] = "GET_RICH_DATA";
        Action[Action["APPROVE"] = 1] = "APPROVE";
        Action[Action["START_ROUTE"] = 2] = "START_ROUTE";
        Action[Action["START_LOADING"] = 3] = "START_LOADING";
        Action[Action["FINISH_ROUTE"] = 4] = "FINISH_ROUTE";
        Action[Action["GET_FINISH_ROUTE_SUMMARY"] = 5] = "GET_FINISH_ROUTE_SUMMARY";
        Action[Action["INSERT_ROUTE"] = 6] = "INSERT_ROUTE";
        Action[Action["DELETE_ROUTE"] = 7] = "DELETE_ROUTE";
        Action[Action["SET_DRIVER"] = 8] = "SET_DRIVER";
        Action[Action["SET_VEHICLE"] = 9] = "SET_VEHICLE";
        Action[Action["GET_WAITING_POINTS"] = 10] = "GET_WAITING_POINTS";
        Action[Action["GET_LOCATIONS"] = 11] = "GET_LOCATIONS";
        Action[Action["GET_COST_ACTIONS"] = 20] = "GET_COST_ACTIONS";
        Action[Action["ADD_ROUTE_COST"] = 21] = "ADD_ROUTE_COST";
        Action[Action["SET_DELIVERED"] = 31] = "SET_DELIVERED";
        Action[Action["SET_FAILED"] = 32] = "SET_FAILED";
        Action[Action["GET_SUMUP_DATA"] = 40] = "GET_SUMUP_DATA";
        Action[Action["GET_COST_LIST"] = 41] = "GET_COST_LIST";
        Action[Action["REMOVE_COST"] = 42] = "REMOVE_COST";
        Action[Action["GET_ROUTE_START"] = 51] = "GET_ROUTE_START";
        Action[Action["GET_ALL_VEHICLES"] = 100] = "GET_ALL_VEHICLES";
        Action[Action["ADD_VEHICLE"] = 101] = "ADD_VEHICLE";
    })(Action = S06Route.Action || (S06Route.Action = {}));
    ;
})(S06Route || (S06Route = {}));
