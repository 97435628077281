import { Packet } from "../Packet";
export class S07Package extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 7;
    }
}
(function (S07Package) {
    let Action;
    (function (Action) {
        Action[Action["INSERT"] = 0] = "INSERT";
        Action[Action["UPDATE"] = 1] = "UPDATE";
        Action[Action["GET_BY_ID_RICH"] = 2] = "GET_BY_ID_RICH";
        Action[Action["GET_ALL_RICH"] = 3] = "GET_ALL_RICH";
        Action[Action["GET_ALL"] = 4] = "GET_ALL";
    })(Action = S07Package.Action || (S07Package.Action = {}));
    ;
})(S07Package || (S07Package = {}));
