import { Packet } from "../Packet";
export class S34WarehouseComponentProductDemand extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 34;
    }
}
(function (S34WarehouseComponentProductDemand) {
    let Action;
    (function (Action) {
        Action[Action["GET_ALL"] = 1] = "GET_ALL";
        Action[Action["GET_RICH_WITH_DEMAND"] = 2] = "GET_RICH_WITH_DEMAND";
        Action[Action["GET_PRODUCT_PACK_INFO"] = 3] = "GET_PRODUCT_PACK_INFO";
        Action[Action["PACK_PRODUCT_PACKAGE"] = 4] = "PACK_PRODUCT_PACKAGE";
        Action[Action["REVERT_PRODUCT_PACKAGE"] = 5] = "REVERT_PRODUCT_PACKAGE";
        Action[Action["RESET_PACKING_CACHE"] = 6] = "RESET_PACKING_CACHE";
        Action[Action["OPEN_BY_SUPPLIER_PRODUCT_ORDER_ID"] = 7] = "OPEN_BY_SUPPLIER_PRODUCT_ORDER_ID";
        Action[Action["OPEN_BY_USAGE_COMPONENT_ID"] = 8] = "OPEN_BY_USAGE_COMPONENT_ID";
        Action[Action["GET_PRODUCT_EXEMPLARY_COMPONENTS"] = 9] = "GET_PRODUCT_EXEMPLARY_COMPONENTS";
        Action[Action["CREATE_PRODUCT_PICKING_COMPLAIN"] = 10] = "CREATE_PRODUCT_PICKING_COMPLAIN";
        Action[Action["GET_PICKING_ONLY"] = 11] = "GET_PICKING_ONLY";
        Action[Action["GET_FUTURE_PRODUCT_LIST"] = 12] = "GET_FUTURE_PRODUCT_LIST";
        Action[Action["GET_ALL_BINS"] = 13] = "GET_ALL_BINS";
        Action[Action["ADD_BIN"] = 14] = "ADD_BIN";
        Action[Action["EDIT_BIN"] = 15] = "EDIT_BIN";
        Action[Action["SET_PRODUCT_REVISION"] = 16] = "SET_PRODUCT_REVISION";
        Action[Action["REMOVE_WAREHOUSE_COMPONENT_REQUIRED"] = 17] = "REMOVE_WAREHOUSE_COMPONENT_REQUIRED";
        Action[Action["GET_WAREHOUSE_COMPONENT_REQUIRED_BY_RICH1"] = 18] = "GET_WAREHOUSE_COMPONENT_REQUIRED_BY_RICH1";
        Action[Action["GET_MANY_WAREHOUSE_COMPONENT_REQUIRED_PRODUCT_COMPLAINT_BY_WAREHOUSE_COMPONENT_REQUIRED_PRODUCT_ID_RICH1"] = 19] = "GET_MANY_WAREHOUSE_COMPONENT_REQUIRED_PRODUCT_COMPLAINT_BY_WAREHOUSE_COMPONENT_REQUIRED_PRODUCT_ID_RICH1";
        Action[Action["SET_COMPLAINT_PRIORITY"] = 20] = "SET_COMPLAINT_PRIORITY";
        Action[Action["REMOVE_BIN"] = 21] = "REMOVE_BIN";
    })(Action = S34WarehouseComponentProductDemand.Action || (S34WarehouseComponentProductDemand.Action = {}));
    ;
})(S34WarehouseComponentProductDemand || (S34WarehouseComponentProductDemand = {}));
