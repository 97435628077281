import { Packet } from "../Packet";
export class S46Materials extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 46;
    }
}
(function (S46Materials) {
    let Action;
    (function (Action) {
        Action[Action["GET_ALL"] = 0] = "GET_ALL";
        Action[Action["CREATE"] = 1] = "CREATE";
        Action[Action["GET_ALL_RICH"] = 2] = "GET_ALL_RICH";
        Action[Action["GET_ONE_RICH"] = 3] = "GET_ONE_RICH";
        Action[Action["CREATE_PARAM"] = 4] = "CREATE_PARAM";
        Action[Action["REMOVE_PARAM"] = 5] = "REMOVE_PARAM";
    })(Action = S46Materials.Action || (S46Materials.Action = {}));
    ;
})(S46Materials || (S46Materials = {}));
