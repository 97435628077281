import { Packet } from "../Packet";
export class S01Login extends Packet {
    constructor(userName, password, sessionId, remember) {
        super();
        this.userName = userName;
        this.password = password;
        this.sessionId = sessionId;
        this.remember = remember;
    }
    getPacketID() {
        return 1;
    }
}
