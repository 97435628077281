import { Packet } from "../Packet";
export class S30Supplier extends Packet {
    constructor(action, supplierName) {
        super();
        this.action = action;
        this.supplierName = supplierName;
    }
    getPacketID() {
        return 30;
    }
}
(function (S30Supplier) {
    let Action;
    (function (Action) {
        Action[Action["GET_ALL"] = 1] = "GET_ALL";
        Action[Action["CREATE"] = 2] = "CREATE";
    })(Action = S30Supplier.Action || (S30Supplier.Action = {}));
    ;
})(S30Supplier || (S30Supplier = {}));
