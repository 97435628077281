import { Packet } from "../Packet";
export class S48Price extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 48;
    }
}
(function (S48Price) {
    let Action;
    (function (Action) {
        Action[Action["CREATE"] = 1] = "CREATE";
    })(Action = S48Price.Action || (S48Price.Action = {}));
    ;
})(S48Price || (S48Price = {}));
