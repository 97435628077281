import { Packet } from "../Packet";
export class S41WarehouseStackTalking extends Packet {
    constructor(action, warehousePackageStocktalkingId) {
        super();
        this.action = action;
        this.warehousePackageStocktalkingId = warehousePackageStocktalkingId;
    }
    getPacketID() {
        return 41;
    }
}
(function (S41WarehouseStackTalking) {
    let Action;
    (function (Action) {
        Action[Action["GET_ALL"] = 1] = "GET_ALL";
        Action[Action["CREATE"] = 2] = "CREATE";
        Action[Action["TOGGLE_LISTEN_MODE"] = 3] = "TOGGLE_LISTEN_MODE";
        Action[Action["GET_RICH"] = 4] = "GET_RICH";
    })(Action = S41WarehouseStackTalking.Action || (S41WarehouseStackTalking.Action = {}));
    ;
})(S41WarehouseStackTalking || (S41WarehouseStackTalking = {}));
