import { Packet } from "../Packet";
export class S28ComponentProduct extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 28;
    }
}
(function (S28ComponentProduct) {
    let Action;
    (function (Action) {
        Action[Action["CREATE"] = 1] = "CREATE";
        Action[Action["GET_ALL"] = 2] = "GET_ALL";
        Action[Action["GET_RICH"] = 3] = "GET_RICH";
        Action[Action["CREATE_PARAM"] = 4] = "CREATE_PARAM";
        Action[Action["MODIFY_PARAM"] = 5] = "MODIFY_PARAM";
        Action[Action["ADD_REQUIRED_COMPONENT"] = 6] = "ADD_REQUIRED_COMPONENT";
        Action[Action["UPDATE_REQUIRED_COMPONENT_PARAMS"] = 7] = "UPDATE_REQUIRED_COMPONENT_PARAMS";
        Action[Action["DELETE_PARAM"] = 8] = "DELETE_PARAM";
        Action[Action["SAVE_NAME"] = 9] = "SAVE_NAME";
        Action[Action["DELETE_REQUIRED_COMPONENT"] = 10] = "DELETE_REQUIRED_COMPONENT";
        Action[Action["CREATE_REQUIRED_ACCESSORY"] = 11] = "CREATE_REQUIRED_ACCESSORY";
        Action[Action["CREATE_NEW_REVISION"] = 12] = "CREATE_NEW_REVISION";
        Action[Action["DELETE_REVISION"] = 13] = "DELETE_REVISION";
        Action[Action["CREATE_COMPONENT_PRODUCT_PACKING"] = 14] = "CREATE_COMPONENT_PRODUCT_PACKING";
        Action[Action["GET_COMPONENT_PRODUCT_DATA_BY_REVISION"] = 15] = "GET_COMPONENT_PRODUCT_DATA_BY_REVISION";
        Action[Action["SET_COMPONENT_REQUIRED_PACKING"] = 16] = "SET_COMPONENT_REQUIRED_PACKING";
        Action[Action["REMOVE_COMPONENT_PRODUCT_PACKING"] = 17] = "REMOVE_COMPONENT_PRODUCT_PACKING";
        Action[Action["GET_COMPONENT_PRODUCT_PACKING_RICH"] = 18] = "GET_COMPONENT_PRODUCT_PACKING_RICH";
        Action[Action["SET_COMPONENT_PRODUCT_PACKING"] = 19] = "SET_COMPONENT_PRODUCT_PACKING";
        Action[Action["ADD_COMPONENT_PRODUCT_PACKING_ACTION_DOCPRINT"] = 20] = "ADD_COMPONENT_PRODUCT_PACKING_ACTION_DOCPRINT";
        Action[Action["REMOVE_COMPONENT_PRODUCT_PACKING_ACTION_DOCPRINT"] = 21] = "REMOVE_COMPONENT_PRODUCT_PACKING_ACTION_DOCPRINT";
        Action[Action["SET_COMPONENT_PRODUCT_PACKING_ACTION_DOCPRINT"] = 22] = "SET_COMPONENT_PRODUCT_PACKING_ACTION_DOCPRINT";
        Action[Action["GET_COMPONENT_PRODUCT_PARAMS"] = 23] = "GET_COMPONENT_PRODUCT_PARAMS";
    })(Action = S28ComponentProduct.Action || (S28ComponentProduct.Action = {}));
    ;
})(S28ComponentProduct || (S28ComponentProduct = {}));
