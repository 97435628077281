import { Packet } from "../Packet";
export class S05Product extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 5;
    }
}
(function (S05Product) {
    let Action;
    (function (Action) {
        Action[Action["GET_ALL"] = 0] = "GET_ALL";
        Action[Action["GET_PRODUCT_RICH"] = 1] = "GET_PRODUCT_RICH";
    })(Action = S05Product.Action || (S05Product.Action = {}));
    ;
})(S05Product || (S05Product = {}));
