import { Packet } from "../Packet";
export class S09DeliveryData extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 9;
    }
}
(function (S09DeliveryData) {
    let Action;
    (function (Action) {
        Action[Action["SET_DELIVERY_SUCCESS"] = 0] = "SET_DELIVERY_SUCCESS";
        Action[Action["SET_DELIVERY_FAILED"] = 1] = "SET_DELIVERY_FAILED";
        Action[Action["ACTION_2"] = 2] = "ACTION_2";
        Action[Action["GET_AVAILABLE_SERVICES"] = 10] = "GET_AVAILABLE_SERVICES";
        Action[Action["ADD_SERVICE"] = 11] = "ADD_SERVICE";
        Action[Action["MANUAL_PACKAGE_STATUS_UPDATE"] = 21] = "MANUAL_PACKAGE_STATUS_UPDATE";
        Action[Action["CANCEL_CASH_OPERATION"] = 31] = "CANCEL_CASH_OPERATION";
        Action[Action["SAVE_CASH"] = 32] = "SAVE_CASH";
        Action[Action["SET_DELIVERY_STATUS"] = 41] = "SET_DELIVERY_STATUS";
        Action[Action["DELETE_SERVICE"] = 42] = "DELETE_SERVICE";
        Action[Action["REMOVE_DELIVERY_DATA"] = 100] = "REMOVE_DELIVERY_DATA";
        Action[Action["ACTION_201"] = 201] = "ACTION_201";
        Action[Action["GET_DELIVERY_DETAILS_RICH"] = 202] = "GET_DELIVERY_DETAILS_RICH";
    })(Action = S09DeliveryData.Action || (S09DeliveryData.Action = {}));
    ;
})(S09DeliveryData || (S09DeliveryData = {}));
