import { Packet } from "../Packet";
export class S35CustomerEdit extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 35;
    }
}
(function (S35CustomerEdit) {
    let Action;
    (function (Action) {
        Action[Action["SAVE"] = 1] = "SAVE";
    })(Action = S35CustomerEdit.Action || (S35CustomerEdit.Action = {}));
    ;
})(S35CustomerEdit || (S35CustomerEdit = {}));
