import { Packet } from "../Packet";
export class S26Address extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 26;
    }
}
(function (S26Address) {
    let Action;
    (function (Action) {
        Action[Action["MODIFY"] = 1] = "MODIFY";
    })(Action = S26Address.Action || (S26Address.Action = {}));
    ;
})(S26Address || (S26Address = {}));
