import { Packet } from "../Packet";
export class S12Threads extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 12;
    }
}
(function (S12Threads) {
    let Action;
    (function (Action) {
        Action[Action["GET_ALL"] = 1] = "GET_ALL";
        Action[Action["GET_RICH"] = 2] = "GET_RICH";
        Action[Action["POSTPONE"] = 3] = "POSTPONE";
        Action[Action["RESPONSE"] = 4] = "RESPONSE";
        Action[Action["SET_FAILED"] = 5] = "SET_FAILED";
    })(Action = S12Threads.Action || (S12Threads.Action = {}));
    ;
})(S12Threads || (S12Threads = {}));
