import { Packet } from "../Packet";
export class S31SupplierProductOrder extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 31;
    }
}
(function (S31SupplierProductOrder) {
    let Action;
    (function (Action) {
        Action[Action["GET_MIDDLEWARE_RICH"] = 1] = "GET_MIDDLEWARE_RICH";
        Action[Action["GET_SUPPLIER_PRODUCT_ORDER"] = 2] = "GET_SUPPLIER_PRODUCT_ORDER";
        Action[Action["REMOVE_SUPPLIER_PRODUCT_ORDER"] = 3] = "REMOVE_SUPPLIER_PRODUCT_ORDER";
        Action[Action["REMOVE_SUPPLIER_PRODUCT_PACKAGE"] = 4] = "REMOVE_SUPPLIER_PRODUCT_PACKAGE";
        Action[Action["SET_SUPPLIER_PRODUCT_PACKAGE_STATUS"] = 5] = "SET_SUPPLIER_PRODUCT_PACKAGE_STATUS";
        Action[Action["GET_SUPPLIER_PRODUCT_ORDER_RICH1"] = 6] = "GET_SUPPLIER_PRODUCT_ORDER_RICH1";
        Action[Action["GENERATE_SUPPLIER_PRODUCT_ORDER_DEFAULT_PACKAGES"] = 7] = "GENERATE_SUPPLIER_PRODUCT_ORDER_DEFAULT_PACKAGES";
    })(Action = S31SupplierProductOrder.Action || (S31SupplierProductOrder.Action = {}));
    ;
})(S31SupplierProductOrder || (S31SupplierProductOrder = {}));
