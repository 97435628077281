<script setup lang="ts">
import { inject, ref, watch } from 'vue';
import { EmpireConnection } from 'websocketclientapi';

const conn = inject('conn') as EmpireConnection;

const WS_URL = import.meta.env.VITE_APP_WEBSOCKET_URL as string;

const endpoints = [
  WS_URL,
  "ws://192.168.1.40:42241/ws",
  "ws://localhost:42241/ws",
  "wss://empire.pietrowe.pl:42241/ws"
];
const endpoint = ref(localStorage.getItem('endpoint') ?? endpoints[0]);

const visible = ref(localStorage.getItem('dev') === 'true');

(window as any)['dev'] = () => {
  visible.value = !visible.value;
  localStorage.setItem('dev', visible.value ? 'true' : 'false');
}

watch(endpoint, () => {
  localStorage.setItem('endpoint', endpoint.value);
  EmpireConnection.setWSUrl(endpoint.value);
  conn.connect();
});
</script>
<template>
  <b-form-floating-label label="Endpoint" v-if="visible">
    <b-form-select v-model="endpoint" :options="endpoints" />
  </b-form-floating-label>
</template>