import { Packet } from "../Packet";
export class S47ManufacturingOperators extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 47;
    }
}
(function (S47ManufacturingOperators) {
    let Action;
    (function (Action) {
        Action[Action["GET_ALL"] = 0] = "GET_ALL";
        Action[Action["CREATE"] = 1] = "CREATE";
        Action[Action["GET_ALL_RICH"] = 2] = "GET_ALL_RICH";
        Action[Action["GET_ONE_RICH"] = 3] = "GET_ONE_RICH";
        Action[Action["TOGGLE_INACTIVE"] = 4] = "TOGGLE_INACTIVE";
    })(Action = S47ManufacturingOperators.Action || (S47ManufacturingOperators.Action = {}));
    ;
})(S47ManufacturingOperators || (S47ManufacturingOperators = {}));
