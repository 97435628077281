<script setup lang="ts">
import { type MessageSchema } from "@/locales/locales";
import { inject, type Ref } from "vue";
import { useI18n } from "vue-i18n";

const i18n = useI18n<{ message: MessageSchema }, string>();
const userData = inject('userData') as Ref<any>;
const { t, getLocaleMessage, locale } = i18n;

const setLang = (locale: string) => {
  i18n.locale.value = locale;
  localStorage.setItem("empire-locale", locale);
}

const logout = () => {
  localStorage.removeItem("SESSION_ID");
  document.cookie = `EMPIRE_SESSION_ID=; path=/; SameSite=Strict; Secure`;
  location.reload(); // TODO - remove this, add packet to log out in WebSocket
}

</script>
<style lang="scss">
.bi2 {
  display: inline-block !important;
  width: 1rem !important;
  height: 1rem !important;
}

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  width: auto !important;
}
</style>
<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="d-none">
    <symbol id="list" viewBox="0 0 16 16">
      <path fill-rule="evenodd"
        d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
    </symbol>
  </svg>
  <header class="navbar bg-dark flex-md-nowrap p-0 shadow" data-bs-theme="dark">
    <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6 text-white" href="javascript:void(0)">pietrowe.pl</a>
    <div class="d-flex align-items-center">
      <b-dropdown variant="primary">
        <template #button-content>
          <i class="flag" :class="locale"></i>{{ locale }}
        </template>
        <b-dropdown-item v-for="loc in $i18n.availableLocales" :key="`locale-${loc}`" :active="locale === loc" @click="setLang(loc)">
          <i class="flag" :class="loc"></i>{{ getLocaleMessage(loc).lang }} ({{ loc }})
        </b-dropdown-item>
      </b-dropdown>
      <span class="me-0 px-3 fs-6 text-white" v-if="userData">{{ userData.fullName }}</span>
      <a href="#" class="me-0 px-3 fs-6 text-white text-decoration-none" @click.prevent="logout()">{{ t('navbar.logout') }}</a>
      <ul class="d-inline-block navbar-nav flex-row d-lg-none">
        <li class="nav-item text-nowrap">
          <button class="nav-link px-3 text-white" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
            <svg class="bi bi2">
              <use xlink:href="#list" />
            </svg>
          </button>
        </li>
      </ul>
    </div>
  </header>
</template>