var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class DataApi {
    static init(token, errorCallback) {
        this.token = token;
        if (errorCallback !== undefined) {
            this.errorCallback = errorCallback;
        }
    }
    static get(url) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield fetch(`https://empire.pietrowe.pl/api${url}`, {
                    method: "GET",
                    headers: this.token === "" ? {
                        "Accept": "application/json"
                    } : {
                        "Accept": "application/json",
                        "Authorization": `Bearer ${this.token}`
                    }
                });
                const data = yield response.json();
                return data;
            }
            catch (err) {
                this.errorCallback(`${url}: ${err}`);
                throw err;
            }
        });
    }
    static post(url, data) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield fetch(`https://empire.pietrowe.pl/api${url}`, {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: this.token === "" ? {
                        "Accept": "application/json",
                        "Content-type": "application/json; charset=UTF-8",
                    } : {
                        "Accept": "application/json",
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": `Bearer ${this.token}`
                    }
                });
                const responseData = yield response.json();
                return responseData;
            }
            catch (err) {
                this.errorCallback(`${url}: ${err}`);
                throw err;
            }
        });
    }
    static getOrdersToSupplier(filters) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.post('/supplier/orderList', filters);
        });
    }
    static calculateRoutes(data) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.post('/delivery/empire/calcRoutes', data);
        });
    }
    static getToDelivery() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.get('/delivery/empire/waiting');
        });
    }
}
DataApi.token = "";
DataApi.errorCallback = (err) => { };
