import { Packet } from "../Packet";
export class S33RoutePoint extends Packet {
    constructor(actionId) {
        super();
        this.actionId = actionId;
    }
    getPacketID() {
        return 33;
    }
}
(function (S33RoutePoint) {
    let Action;
    (function (Action) {
        Action[Action["DELIVERY_REMOVE_SKIP"] = 1] = "DELIVERY_REMOVE_SKIP";
    })(Action = S33RoutePoint.Action || (S33RoutePoint.Action = {}));
    ;
})(S33RoutePoint || (S33RoutePoint = {}));
