import { Packet } from "../Packet";
export class S43WarehouseMaterial extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 43;
    }
}
(function (S43WarehouseMaterial) {
    let Action;
    (function (Action) {
        Action[Action["GET_ALL"] = 0] = "GET_ALL";
        Action[Action["CREATE"] = 1] = "CREATE";
        Action[Action["GET_ONE_RICH"] = 2] = "GET_ONE_RICH";
    })(Action = S43WarehouseMaterial.Action || (S43WarehouseMaterial.Action = {}));
    ;
})(S43WarehouseMaterial || (S43WarehouseMaterial = {}));
