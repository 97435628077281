<script setup lang="ts">
export type ShowModalFunc = (component: any, properties: any) => number;

import { inject, markRaw, ref, type Ref } from 'vue';

const modals = ref<any[]>([]);
let modalId = 0;
const showModal: ShowModalFunc = (component, properties) => {
  const id = modalId++;
  modals.value.push({
    id: id,
    component: markRaw(component),
    properties: properties
  });
  return id;
};
const showModalFunc = inject('showModalFunc') as Ref<ShowModalFunc>;
showModalFunc.value = showModal;

const removeModal = (id: number) => {
  const index = modals.value.findIndex((modal) => modal.id === id);
  if (index !== -1) {
    modals.value.splice(index, 1);
  }
};
</script>
<template>
  <component v-for="modal in modals" :is="modal.component" :key="modal.id" :ref="modal.id" noTrap @hidden="removeModal(modal.id)"
    :properties="modal.properties" />
</template>