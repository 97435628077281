import { Packet } from "../Packet";
export class S45WarehouseLocation extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 45;
    }
}
(function (S45WarehouseLocation) {
    let Action;
    (function (Action) {
        Action[Action["GET_ALL"] = 0] = "GET_ALL";
        Action[Action["CREATE"] = 1] = "CREATE";
    })(Action = S45WarehouseLocation.Action || (S45WarehouseLocation.Action = {}));
    ;
})(S45WarehouseLocation || (S45WarehouseLocation = {}));
