import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import 'bootstrap/dist/css/bootstrap.css'
import './assets/main.scss'

import { createApp, ref, render, watch } from 'vue'

import App from './App.vue'
import router from './router'

import * as Sentry from "@sentry/vue"
import { BToast, createBootstrap, type BaseColorVariant } from 'bootstrap-vue-next'
import { C03LoginResult, DataApi, EmpireConnection, S00Ping, S01Login } from 'websocketclientapi'
import { i18n } from './locales/locales'


let lastFrame = Date.now();
const updateFrame = () => {
  let now = Date.now();
  if (now - lastFrame > 250) {
    console.warn('render tick time', now - lastFrame);
  }
  lastFrame = now;
  requestAnimationFrame(updateFrame);
}
requestAnimationFrame(updateFrame);

const WS_URL = localStorage.getItem('endpoint') ?? import.meta.env.VITE_APP_WEBSOCKET_URL as string;
const loading = ref(true);
const userData = ref();
EmpireConnection.setWSUrl(WS_URL);
const conn = EmpireConnection.getInstance();
const showModalFunc = ref();

export type ShowToast = (msg: string, type?: keyof BaseColorVariant, title?: string) => void;

const showToast: ShowToast = (msg: string, type: keyof BaseColorVariant = "danger", title: string = "Błąd") => {
  // render() removes the element from the div, so we need to create a new one
  const div = document.createElement('div');
  div.classList.add('p-2');
  document.getElementById('toast-container')!.appendChild(div);
  render(<BToast
    key={`toast-${Math.random()}`}
    title={title}
    variant={type}
    modelValue={5000}
    interval={100}
    progressProps={{ variant: type }}>{msg}</BToast>, div);
  setTimeout(() => {
    div.remove();
  }, 5000);
};

conn.setErrorCallback(showToast);
conn.setSendPacketCallback((packet) => {
  if (packet instanceof S01Login || packet instanceof S00Ping) {
    return;
  }
  const str = JSON.stringify(packet);
  Sentry.captureMessage(str);
});

const setSessionID = (sessionID?: string) => {
  if (sessionID === undefined) {
    localStorage.removeItem("SESSION_ID");
    document.cookie = `EMPIRE_SESSION_ID=; path=/; SameSite=Strict; Secure`;
    DataApi.init("", showToast);
  } else {
    localStorage.setItem("SESSION_ID", sessionID);
    document.cookie = `EMPIRE_SESSION_ID=${sessionID}; path=/; SameSite=Strict; Secure`;
    DataApi.init(sessionID, showToast);
  }
}

watch(userData, () => {
  if (userData.value === undefined) {
    Sentry.setUser(null);
  } else {
    Sentry.setUser({ username: userData.value.nick });
  }
});

const notLogged = () => {
  userData.value = undefined;
  const currRoute = router.currentRoute.value;
  if (currRoute.name !== 'login' && currRoute.name !== 'register') {
    router.push('/auth/login');
  }
};

conn.start(async () => {
  const sessionID = localStorage.getItem("SESSION_ID");
  if (sessionID !== null) {
    try {
      const packet: C03LoginResult = await conn.send(new S01Login(null, null, sessionID, true));
      if (packet.succeded) {
        if (packet.sessionId !== null && packet.sessionId !== undefined) {
          setSessionID(packet.sessionId);
        }
        userData.value = packet.userData;
        const currRoute = router.currentRoute.value;
        if (currRoute.name === 'login' || currRoute.name === 'register') {
          router.push('/');
        }
        loading.value = false;
      } else {
        throw new Error("Invalid session ID");
      }
    } catch (e: any) {
      setSessionID();
      notLogged();
      loading.value = false;
    }
  } else {
    notLogged();
    loading.value = false;
  }
});

router.beforeEach((to, from) => {
  if (loading.value) {
    return true;
  }
  if (userData.value === undefined) {
    if (to.name !== 'login' && to.name !== 'register') {
      return false;
    }
  } else {
    if (to.name === 'login' || to.name === 'register') {
      return false;
    }
  }
  return true;
});

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://78dd68cafb935807981390e81da15da6@o4508195288186880.ingest.de.sentry.io/4508195289497680",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  enabled: import.meta.env.MODE === 'production' && WS_URL === 'wss://empire.pietrowe.pl:42241/ws',
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/empire3\.pietrowe\.pl\//],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.provide("conn", conn);
app.provide("userData", userData);
app.provide("loading", loading);
app.provide("showModalFunc", showModalFunc);
app.provide("setSessionID", setSessionID);
app.provide("showToast", showToast);

app.use(createBootstrap()); // Important ~bootstrap docs
app.use(i18n);
app.use(router);

app.mount('#app')
